import * as React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ecommerce_slider_1 from "../images/photo-retouching.webp"
import ecommerce_slider_2 from "../images/color-corrections.webp"
import ecommerce_slider_3 from "../images/background-removal.webp"
import ecommerce_slider_4 from "../images/lighting-shadow-corrections.webp"
import ecommerce_slider_5 from "../images/scaling-adjustments.webp"
import ecommerce_slider_6 from "../images/custom-color-tone.webp"
import { Trans } from "gatsby-plugin-react-i18next"

const SimpleSlider = () => {
  function SampleNextArrow(props) {
    const { onClick } = props
    return <div className="cut-arrow-right" onClick={onClick} />
  }

  function SamplePrevArrow(props) {
    const { onClick } = props
    return <div className="cut-arrow-left" onClick={onClick} />
  }
  const settings = {
    infinite: true,
    className: "center",
    infinite: true,
    dots: true,
    arrows: true,
    centerMode: true,
    centerPadding: "80px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <>
      <div>
        <div className="slider-main">
          <Slider {...settings}>
            <div>
              <div className="cate-slider-box">
                <div className="back-color-slider-box">
                  <img
                    src={ecommerce_slider_1}
                    alt="fashion-slider-img-1"
                    width="554px"
                    height="552px"
                  ></img>
                  <div className="fh-text">
                    <h2>
                      <Trans>Photo Retouching</Trans>
                    </h2>
                    <p>
                      <Trans>
                      Enhance product photos with expert adjustments
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="cate-slider-box">
                <div className="back-color-slider-box">
                  <img
                    src={ecommerce_slider_2}
                    alt="fashion-slider-img-2"
                    width="554px"
                    height="552px"
                  ></img>
                  <div className="fh-text">
                    <h2>
                      <Trans>Color Corrections</Trans>
                    </h2>
                    <p>
                      <Trans>
                      Ensure standout visuals with precise color-matching
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="cate-slider-box">
                <div className="back-color-slider-box">
                  <img
                    src={ecommerce_slider_3}
                    alt="fashion-slider-img-3"
                    width="554px"
                    height="552px"
                  ></img>
                  <div className="fh-text">
                    <h2>
                      <Trans>Background Removal</Trans>
                    </h2>
                    <p>
                      <Trans>
                      Transform backgrounds for an elevated look
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="cate-slider-box">
                <div className="back-color-slider-box">
                  <img
                    src={ecommerce_slider_4}
                    alt="fashion-slider-img-1"
                    width="554px"
                    height="552px"
                  ></img>
                  <div className="fh-text">
                    <h2>
                      <Trans>Lighting & Shadow Corrections</Trans>
                    </h2>
                    <p>
                      <Trans>
                      Create visual depth with expert shadow additions
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="cate-slider-box">
                <div className="back-color-slider-box">
                  <img
                    src={ecommerce_slider_5}
                    alt="fashion-slider-img-1"
                    width="554px"
                    height="552px"
                  ></img>
                  <div className="fh-text">
                    <h2>
                      <Trans>Scaling Adjustments</Trans>
                    </h2>
                    <p>
                      <Trans>
                      Seamlessly fit digital catalogs with cropping
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="cate-slider-box">
                <div className="back-color-slider-box">
                  <img
                    src={ecommerce_slider_6}
                    alt="fashion-slider-img-1"
                    width="554px"
                    height="552px"
                  ></img>
                  <div className="fh-text">
                    <h2>
                      <Trans>Custom Color Tone</Trans>
                    </h2>
                    <p>
                      <Trans>
                      Infuse magic with high-resolution color enhancement
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  )
}
export default SimpleSlider
