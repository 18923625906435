import React, { useEffect, useState } from "react"
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import fashion_1 from "../images/ecommerce-intro-img.webp"
import fashion_1_mob from "../images/ecommerce-intro-img.webp"
import Testimonials from "../components/Testimonials.js"
import Ecommerce_Slider from "../components/Ecommerce_Slider.js"
import Sucslider from "../components/success_slider.js"
import fashion_icon_1 from "../images/leading.webp"
import fashion_icon_2 from "../images/stand.webp"
import fashion_icon_3 from "../images/high-volume.webp"
import fashion_icon_4 from "../images/pricing.webp"
import fashion_2 from "../images/ecommerce-bottom-img.webp"
import fashion_2_mob from "../images/ecommerce-bottom-img.webp"
import ecommerce_slider_1 from "../images/photo-retouching.webp"
import ecommerce_slider_2 from "../images/color-corrections.webp"
import ecommerce_slider_3 from "../images/background-removal.webp"
import ecommerce_slider_4 from "../images/lighting-shadow-corrections.webp"
import ecommerce_slider_5 from "../images/scaling-adjustments.webp"
import ecommerce_slider_6 from "../images/custom-color-tone.webp"
import { SuccessStoryData } from "../data/fashionSuccessStoryData.js"
import { TestimonialData } from "../data/fashionTestimonialData.js"
import setKey, { FirstCapital } from "../utils/setKey.js"
import { Trans, useI18next, Link } from "gatsby-plugin-react-i18next"
import { navigate, graphql } from "gatsby"

var Fashion_keyword = "E-commerce Photo Editing"

const Ecommerce = ({ location, ...rest }) => {
  const { languages, changeLanguage } = useI18next()

  const [dataKey, setDataKey] = useState("")
  const [locationKey, setLocationKey] = useState("")
  const [classname, setClassname] = useState("")

  useEffect(() => {
    typeof window !== "undefined" &&
    localStorage.getItem("gatsby-i18next-language") === "de"
      ? setClassname("de")
      : setClassname("")
  })

  useEffect(async () => {
    let data = await setKey(location, "utm_term_fashion", Fashion_keyword)
    if (data.length > 0) {
      setDataKey(data[0])
      setLocationKey(data[1])
    }

    return () => {
      data = null
    }
  }, [])

  useEffect(() => {
    if (locationKey === "Germany") {
      changeLanguage(languages[2])
    } else {
      if (
        rest.pageContext.language === "de" &&
        localStorage.getItem("location") !== "Germany"
      ) {
        localStorage.setItem("gatsby-i18next-language", "en")
        navigate("/ecommerce-photo-editing")
      }
    }
  }, [locationKey])

  return (
    <>
      <Header
        metaTitle={
          typeof window !== "undefined" &&
          localStorage.getItem("gatsby-i18next-language") === "de"
            ? "Pixelgenaue Präzision bei E-Commerce-Fotobearbeitungsdiensten"
            : "Pixel Perfect Precision in E-commerce Photo Editing Services"
        }
        metaDescription={
          typeof window !== "undefined" &&
          localStorage.getItem("gatsby-i18next-language") === "de"
            ? "Von der präzisen Farbanpassung bis zur nahtlosen Hintergrundentfernung – mit unseren spezialisierten E-Commerce-Fotobearbeitungsdiensten stellen Sie sicher, dass Ihre Produkte hervorstechen"
            : "From precise color-matching to seamless background removal, ensure your products stand out with our specialized e-commerce photo editing services"
        }
        metakeyword={
          typeof window !== "undefined" &&
          localStorage.getItem("gatsby-i18next-language") === "de"
            ? "bild nachbearbeitung"
            : "e-commerce photo editing, Photo Editing Services, Photo Editing Outsourcing"
        }
        canonical="https://www.photoeditingoutsourcing.com/ecommerce-photo-editing/"
      />

      <div className="home-sec">
        <div className="zindex-div">
          <div className="product-sec fashion-sec">
            <div className="ps-first">
              <div className="container">
                <div className="home-first-sec">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className={`text-box ${
                          classname === "de" ? "text-box-german" : ""
                        }`}
                      >
                        <h1>
                          <Trans>
                            {dataKey && dataKey
                              ? FirstCapital(dataKey)
                              : Fashion_keyword}
                          </Trans>
                        </h1>
                        <p>
                          <Trans>
                          Photo Editing Outsourcing effortlessly meets diverse product image requirements across various e-commerce platforms. 
                          </Trans>
                        </p>
                        <div className="btn-home-sec">
                          <Link to="/contact-us" className="get-started">
                            <Trans>Get started</Trans>
                          </Link>
                          <Link to="/portfolio?tab=1" className="see-port">
                            <Trans>see portfolio </Trans>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="homeimg-box">
                        <img
                          src={fashion_1}
                          alt="fashion-banner-img"
                          className="img-1 desktop-img-1"
                          width="488px"
                          height="578px"
                        ></img>
                        <img
                          src={fashion_1}
                          alt="fashion-banner-img-mob"
                          className="mob-img-1"
                          width="244px"
                          height="289px"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-3-sec">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-12 right-len">
                    <div className="row row-2">
                      <div className="col-md-6 col-lg-3 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-1">
                            {" "}
                            <img
                              src={fashion_icon_1}
                              alt="fashion-icon-img-1"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          <h1>
                            <Trans>Leading Industry Partner</Trans>
                          </h1>
                          <p>
                            <Trans>
                            Top choice for E-commerce and online businesses 
                            </Trans>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-2">
                            {" "}
                            <img
                              src={fashion_icon_2}
                              alt="fashion-icon-img-2"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          <h1>
                            <Trans>Stand Out Visuals</Trans>
                          </h1>
                          <p>
                            <Trans>
                            Photos that emphasize the quality of the products
                            </Trans>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-1">
                            {" "}
                            <img
                              src={fashion_icon_3}
                              alt="fashion-icon-img-3"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          <h1>
                            <Trans>High Volume Handling</Trans>
                          </h1>
                          <p>
                            <Trans>
                            Excelling in large-scale photo editing tasks
                            </Trans>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-2">
                            {" "}
                            <img
                              src={fashion_icon_4}
                              alt="fashion-icon-img-4"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          <h1>
                            <Trans>Tailored Pricing Plans</Trans>
                          </h1>
                          <p>
                            <Trans>
                            Flexible plans for unique assignments
                            </Trans>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12 our-mission">
                    <div className="row  align-items-center">
                      <div className="col-md-12 col-lg-6">
                        <div className="cat-slider-text">
                          <h4>
                            <Trans>PERFECTION</Trans>
                          </h4>
                          <h2>
                            <Trans>
                            E-commerce Photo Editing Excellence 
                            </Trans>
                          </h2>
                          {typeof window !== "undefined" &&
                          localStorage.getItem("gatsby-i18next-language") ===
                            "de" ? (
                            <p>
                              <Trans>Portrait&fashiondesc</Trans>
                            </p>
                          ) : (
                            <p>
                              Improve the visualization of your product images with Photo Editing Outsourcing. We cater to all your specific e-commerce photo editing requirements, including image resolution, size, and format, across various e-commerce platforms, saving you time and effort.
                            </p>
                          )}
                          <p>
                            <Trans>
                            For over 15 years, Photo Editing Outsourcing has delivered pixel-perfect, personalized photo editing services at competitive prices, ensuring your e-commerce product images stand out online. 
                            </Trans>
                          </p>
                          <p>
                            <Trans>
                            To avoid inconsistent image presentation on e-commerce websites, we maintain consistent lighting, size, angle, and background across all product photos. Trust Photo Editing Outsourcing as your reliable partner for expertise, quality, and efficiency in e-commerce photo editing.
                            </Trans>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="img-box-about">
                          <img
                            src={fashion_2}
                            alt="fashion-img-2"
                            className="desktop-img-1"
                            width="604px"
                            height="449px"
                          ></img>
                          <img
                            src={fashion_2_mob}
                            alt="fashion-img-2-mob"
                            className="mob-img-1"
                            width="333px"
                            height="250px"
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="fashion-slider">
              <div className="cat-slider-text">
                <h4>
                  <Trans>ECOMMERCE Photo Editing</Trans>
                </h4>
                <h2
                  className={`fashion-photo-service ${
                    classname === "de" && "fashion-photo-service-german"
                  }`}
                >
                  <Trans>Improving Your Online Sales</Trans>
                </h2>
                <p className="fashion-photo-service">
                  <Trans>
                  We provide comprehensive E-commerce Photo Editing Services that optimize your images. From enhancing colors and textures to ensuring flawless presentations, Photo Editing Outsourcing services go beyond standard editing to bring visual perfection to your product visuals.
                  </Trans>
                </p>
               
              </div>
              <div className="slider-sh">
                <Ecommerce_Slider />
              </div>

              <div className="fh-slider-mob">
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={ecommerce_slider_1}
                      alt="fashion-slider-4-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Photo Retouching</Trans>
                      </h2>
                      <p>
                        <Trans>
                        Enhance product photos with expert adjustments
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={ecommerce_slider_2}
                      alt="fashion-slider-5-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Color Correction</Trans>
                      </h2>
                      <p>
                        <Trans>
                        Ensure standout visuals with precise color-matching
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={ecommerce_slider_3}
                      alt="fashion-slider-1-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Background Removal</Trans>
                      </h2>
                      <p>
                        <Trans>
                        Transform backgrounds for an elevated look
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={ecommerce_slider_4}
                      alt="fashion-slider-2-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Lighting & Shadow Corrections</Trans>
                      </h2>
                      <p>
                        <Trans>
                        Create visual depth with expert shadow additions
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={ecommerce_slider_5}
                      alt="fashion-slider-3-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Scaling Adjustments</Trans>
                      </h2>
                      <p>
                        <Trans>
                        Seamlessly fit digital catalogs with cropping
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={ecommerce_slider_6}
                      alt="fashion-slider-6-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Custom Color Tone</Trans>
                      </h2>
                      <p>
                        <Trans>
                        Infuse magic with high-resolution color enhancement
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="how-sec">
              <div className="container">
                <h2>
                  <Trans>Photo Editing Outsourcing</Trans>
                </h2>
                {typeof window !== "undefined" &&
                localStorage.getItem("gatsby-i18next-language") === "de" ? (
                  <p className="how-germen-para-text">
                    <Trans>workingdesc</Trans>
                  </p>
                ) : (
                  <p>
                    <Trans>
                     Outsource E-Commerce Photo Editing and give your customers a realistic shopping experience 
                    </Trans>
                  </p>
                )}
              </div>
            </div>
            <div className="category-slider-sec testimonials-sec ecommerce-testi">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="cat-slider-text">
                      <h4>
                        <Trans>Word of praise</Trans>
                      </h4>
                      <h3>
                        <Trans>Testimonials</Trans>
                      </h3>
                      <p>
                        <Trans>
                          Don’t just take our word for it. Discover what our
                          customers have to say about us.
                        </Trans>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
              </div>
              <div className="main-slider">
                <div className="container cate-slider">
                  <Testimonials
                    locationKey={locationKey}
                    TestimonialData={TestimonialData}
                  />
                </div>
              </div>
            </div>
            <div className="Success-sec">
              <div className="container">
                <div className="cat-slider-text">
                  <h4>
                    <Trans>Success</Trans>
                  </h4>
                  <h3>
                    <Trans>Success Stories</Trans>
                  </h3>
                  <p>
                    <Trans>
                      Delivering true value to every customer. Find out how we
                      do it.
                    </Trans>
                  </p>
                </div>
                <div className="main-slider">
                  <div className="container cate-slider">
                    <Sucslider
                      locationKey={locationKey}
                      SuccessStoryData={SuccessStoryData}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
            {/* <GetStart
              dataKey={FirstCapital(dataKey)}
              pageKeyword={Fashion_keyword}
            /> */}
            <div className="start-sec">
              <div className="container">
                <div className="row  align-items-center">
                  <div className="col-md-12 col-lg-9">
                    <div className="start-text">
                        
                        {typeof window !== "undefined" &&
                        localStorage.getItem("gatsby-i18next-language") === "de" ? (
                          <h1>
                            <Trans>Outsource E-commerce Photo Editing Services</Trans>
                          </h1>
                        ) : (
                          <h1>
                            <Trans>
                            Outsource E-commerce Photo Editing Services
                            </Trans>
                          </h1>
                        )}
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-3">
                    <div className="start-btn">
                      <Link to="/contact-us">
                        <button>
                          <Trans>Get started</Trans>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Ecommerce

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
